<template>
    <v-card class="pa-3">
        <v-card-title>
            {{ $t('tutorial.exchangeHowToTitle') }}
            {{ exchange | BlockchainTypeFormatter }}
        </v-card-title>
        <v-card-subtitle>
            <p
                v-html="$t('tutorial.exchangeHowToDescription', { 'exchange': $options.filters.BlockchainTypeFormatter(exchange), 'steps': getSteps.length })">
            </p>
            <p v-if="exchange == 'GENERIC'" v-html="$t('tutorial.exchangeHowToDescriptionExcel')"></p>
            <p v-if="exchange == 'CRYPTOCOM'" v-html="$t('tutorial.cryptoComExternalGuide')"></p>
            <p v-if="exchange == 'CRYPTOCOMEXCHANGE'" v-html="$t('tutorial.cryptoComExchangeExternalGuide')"></p>
            <p v-if="exchange == 'COINBASE'" v-html="$t('tutorial.coinbaseExternalGuide')"></p>
            <p v-if="exchange == 'BITPANDA'" v-html="$t('tutorial.bitpandaExternalGuide')"></p>
            <p v-if="exchange == 'YOUNGPLATFORM'" v-html="$t('tutorial.youngPlatformExternalGuide')"></p>
            <p v-if="exchange == 'KRAKEN'" v-html="$t('tutorial.krakenExternalGuide')"></p>
            <p v-if="exchange == 'KUCOIN'" v-html="$t('tutorial.kucoinExternalGuide')"></p>
            <p v-if="exchange == 'CRYPTOSMART'" v-html="$t('tutorial.cryptosmartExternalGuide')"></p>
            <p v-if="exchange == 'BYBIT'" v-html="$t('tutorial.bybitExternalGuide')"></p>
            <p v-if="exchange == 'UPHOLD'" v-html="$t('tutorial.upholdExternalGuide')"></p>
            <p v-if="exchange == 'RELAI'" v-html="$t('tutorial.relaiExternalGuide')"></p>
            <p v-if="exchange == 'XAMAN'" v-html="$t('tutorial.xamanExternalGuide')"></p>
            <p v-if="exchange == 'PIONEX'" v-html="$t('tutorial.pionexExternalGuide')"></p>
            <p v-if="exchange == 'SWISSBORG'" v-html="$t('tutorial.swissborgExternalGuide')"></p>
            <p v-if="exchange == 'LEDGERCSV'" v-html="$t('tutorial.ledgerCsvExternalGuide')"></p>
            <p v-if="exchange == 'ONETRADING'" v-html="$t('tutorial.oneTradingExternalGuide')"></p>
            <p v-if="exchange == 'SORARE'" v-html="$t('tutorial.sorareExternalGuide')"></p>
            <p v-if="exchange == 'CONIO'" v-html="$t('tutorial.conioExternalGuide')"></p>
            <v-alert v-if="exchange == 'BITTREX'" colored-border type="error" :border="'left'" elevation="2" icon="mdi-alert"><p v-html="$t('tutorial.bittrexWithdrawalsAndDeposits')"></p></v-alert>
            <!--<v-alert v-if="exchange == 'COINBASE'" colored-border type="error" :border="'left'" elevation="2" icon="mdi-alert"><p v-html="$t('tutorial.coinbaseAdvancedTrades')"></p></v-alert>-->
            <v-alert v-if="exchange == 'CONIO'" colored-border type="error" :border="'left'" elevation="2" icon="mdi-alert"><p v-html="$t('tutorial.conioPreviousYears')"></p></v-alert>
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-carousel hide-delimiters v-if="!$SUPPORTED_EXCHANGE_CSV.includes(exchange) && exchange != 'STAKETAX'">
                        <v-carousel-item v-for="(step, index) in getSteps" :key="index">
                            <v-card elevation="0">
                                <v-card-title>
                                    <h3>STEP {{ index + 1 }}</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            <p v-html="step.description"></p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="step.image" no-gutters>
                                        <v-col>
                                            <v-img :src="step.image" contain @click="openDialog"
                                                class="clickable-image"></v-img>
                                            <v-dialog v-model="dialogVisible" width="100%">
                                                <v-card>
                                                    <v-img :src="step.image"></v-img>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                    <ol v-else>
                        <li v-for="(step, index) in getSteps" :key="index">
                            <p v-html="step.description"></p>
                        </li>
                    </ol>
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-col>
                    <v-alert color="#7289DA" elevation="2" colored-border type="info" :border="'left'" dense
                        @click="goToDiscord">
                        <p v-html="$t('tutorial.helpDiscord')"></p>
                        <template v-slot:prepend>
                            <v-icon color="#7289DA" class="fa-brands fa-discord mr-5"></v-icon>
                        </template>
                    </v-alert>
                </v-col>
            </v-row>
            -->
            <v-row>
                <v-col>
                    <v-alert color="accent" elevation="2" colored-border type="info" :border="'left'" dense
                        @click="sendEmail">
                        <p v-html="$t('tutorial.helpEmail', { 'subject': getSubject })"></p>
                        <template v-slot:prepend>
                            <v-icon color="accent" class="ml-1 mr-5">mdi-email</v-icon>
                        </template>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
  
<script>
export default {
    name: "exchange-tutorial-carousel",
    props: {
        exchange: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    computed: {
        getSubject() {
            return encodeURIComponent(`${this.$t('tutorial.supportRequest')} ${this.$options.filters.BlockchainTypeFormatter(this.exchange)}`)
        },
        getSteps() {
            if (this.exchange == 'BINANCE') {
                return [
                    {
                        description: this.$t('tutorial.binanceStep1'),
                        image: require('@/assets/tutorial/BINANCE/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.binanceStep2'),
                        image: require('@/assets/tutorial/BINANCE/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.binanceStep3'),
                        image: require('@/assets/tutorial/BINANCE/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.binanceStep4'),
                        image: require('@/assets/tutorial/BINANCE/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.binanceStep5'),
                        image: require('@/assets/tutorial/BINANCE/STEP_5.png')
                    },
                    {
                        description: this.$t('tutorial.binanceStep6'),
                    }
                ];
            } else if (this.exchange == 'CRYPTOCOM') {
                return [
                    {
                        description: this.$t('tutorial.cryptoComStep1'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComStep2'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComStep3'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComStep4'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComStep5'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComStep6'),
                    }
                ];
            } else if (this.exchange == 'CRYPTOCOMEXCHANGE') {
                return [
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep1'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep2'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep3'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep4'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep5'),
                    },
                    {
                        description: this.$t('tutorial.cryptoComExchangeStep6'),
                    }
                ];
            } else if (this.exchange == 'BYBIT') {
                return [
                    {
                        description: this.$t('tutorial.bybitStep1'),
                    },
                    {
                        description: this.$t('tutorial.bybitStep2'),
                    },
                    {
                        description: this.$t('tutorial.bybitStep3'),
                    },
                    {
                        description: this.$t('tutorial.bybitStep4'),
                    },
                    {
                        description: this.$t('tutorial.bybitStep5'),
                    },
                    {
                        description: this.$t('tutorial.bybitStep6'),
                    }
                ];
            } else if (this.exchange == 'NEXO') {
                return [
                    {
                        description: this.$t('tutorial.nexoStep1'),
                    },
                    {
                        description: this.$t('tutorial.nexoStep2'),
                    },
                    {
                        description: this.$t('tutorial.nexoStep3'),
                    },
                    {
                        description: this.$t('tutorial.nexoStep4'),
                    },
                    {
                        description: this.$t('tutorial.nexoStep5'),
                    },
                ];
            } else if (this.exchange == 'STAKETAX') {
                return [
                    {
                        description: this.$t('tutorial.stakeTaxStep1'),
                    },
                    {
                        description: this.$t('tutorial.stakeTaxStep2'),
                    },
                    {
                        description: this.$t('tutorial.stakeTaxStep3'),
                    },
                    {
                        description: this.$t('tutorial.stakeTaxStep4'),
                    },
                    {
                        description: this.$t('tutorial.stakeTaxStep5'),
                    },
                ];
            } else if (this.exchange == 'TERRACLASSIC') {
                return [
                    {
                        description: this.$t('tutorial.terraClassicStep1'),
                    },
                    {
                        description: this.$t('tutorial.terraClassicStep2'),
                    },
                    {
                        description: this.$t('tutorial.terraClassicStep3'),
                    },
                    {
                        description: this.$t('tutorial.terraClassicStep4'),
                    },
                    {
                        description: this.$t('tutorial.terraClassicStep5'),
                    },
                ];
            } else if (this.exchange == 'TERRA') {
                return [
                    {
                        description: this.$t('tutorial.terraStep1'),
                    },
                    {
                        description: this.$t('tutorial.terraStep2'),
                    },
                    {
                        description: this.$t('tutorial.terraStep3'),
                    },
                    {
                        description: this.$t('tutorial.terraStep4'),
                    },
                    {
                        description: this.$t('tutorial.terraStep5'),
                    },
                ];
            } else if (this.exchange == 'ALGORAND') {
                return [
                    {
                        description: this.$t('tutorial.algorandStep1'),
                    },
                    {
                        description: this.$t('tutorial.algorandStep2'),
                    },
                    {
                        description: this.$t('tutorial.algorandStep3'),
                    },
                    {
                        description: this.$t('tutorial.algorandStep4'),
                    },
                    {
                        description: this.$t('tutorial.algorandStep5'),
                    },
                ];
            } else if (this.exchange == 'COSMOS') {
                return [
                    {
                        description: this.$t('tutorial.cosmosStep1'),
                    },
                    {
                        description: this.$t('tutorial.cosmosStep2'),
                    },
                    {
                        description: this.$t('tutorial.cosmosStep3'),
                    },
                    {
                        description: this.$t('tutorial.cosmosStep4'),
                    },
                    {
                        description: this.$t('tutorial.cosmosStep5'),
                    },
                ];
            } else if (this.exchange == 'COINBASE') {
                return [
                    {
                        description: this.$t('tutorial.coinbaseStep1'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep2'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep3'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep4'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep5'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep6'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep7'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep8'),
                    },
                    {
                        description: this.$t('tutorial.coinbaseStep9'),
                    },
                ];
            } else if (this.exchange == 'BITPANDA') {
                return [
                    {
                        description: this.$t('tutorial.bitpandaStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitpandaStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitpandaStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitpandaStep4'),
                    },
                ];
            } else if (this.exchange == 'UPHOLD') {
                return [
                    {
                        description: this.$t('tutorial.upholdStep1'),
                    },
                    {
                        description: this.$t('tutorial.upholdStep2'),
                    },
                    {
                        description: this.$t('tutorial.upholdStep3'),
                    },
                    {
                        description: this.$t('tutorial.upholdStep4'),
                    },
                ];
            } else if (this.exchange == 'POCKETBITCOIN') {
                return [
                    {
                        description: this.$t('tutorial.pocketBitcoinStep1'),
                    },
                    {
                        description: this.$t('tutorial.pocketBitcoinStep2'),
                    },
                    {
                        description: this.$t('tutorial.pocketBitcoinStep3'),
                    },
                    {
                        description: this.$t('tutorial.pocketBitcoinStep4'),
                    },
                ];
            } else if (this.exchange == 'YOUHODLER') {
                return [
                    {
                        description: this.$t('tutorial.youhodlerStep1'),
                    },
                    {
                        description: this.$t('tutorial.youhodlerStep2'),
                    },
                    {
                        description: this.$t('tutorial.youhodlerStep3'),
                    },
                    {
                        description: this.$t('tutorial.youhodlerStep4'),
                    },
                ];
            } else if (this.exchange == 'COINLIST') {
                return [
                    {
                        description: this.$t('tutorial.coinlistStep1'),
                    },
                    {
                        description: this.$t('tutorial.coinlistStep2'),
                    },
                    {
                        description: this.$t('tutorial.coinlistStep3'),
                    },
                    {
                        description: this.$t('tutorial.coinlistStep4'),
                    },
                    {
                        description: this.$t('tutorial.coinlistStep5'),
                    },
                ];
            }else if (this.exchange == 'BITRUE') {
                return [
                    {
                        description: this.$t('tutorial.bitrueStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitrueStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitrueStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitrueStep4'),
                    },
                    {
                        description: this.$t('tutorial.bitrueStep5'),
                    },
                ];
            } else if (this.exchange == 'XAMAN') {
                return [
                    {
                        description: this.$t('tutorial.xamanStep1'),
                    },
                    {
                        description: this.$t('tutorial.xamanStep2'),
                    },
                    {
                        description: this.$t('tutorial.xamanStep3'),
                    },
                    {
                        description: this.$t('tutorial.xamanStep4'),
                    },
                ];
            } else if (this.exchange == 'PIONEX') {
                return [
                    {
                        description: this.$t('tutorial.pionexStep1'),
                    },
                    {
                        description: this.$t('tutorial.pionexStep2'),
                    },
                    {
                        description: this.$t('tutorial.pionexStep3'),
                    },
                    {
                        description: this.$t('tutorial.pionexStep4'),
                    },
                ];
            } else if (this.exchange == 'RELAI') {
                return [
                    {
                        description: this.$t('tutorial.relaiStep1'),
                    },
                    {
                        description: this.$t('tutorial.relaiStep2'),
                    },
                    {
                        description: this.$t('tutorial.relaiStep3'),
                    },
                    {
                        description: this.$t('tutorial.relaiStep4'),
                    },
                ];
            } else if (this.exchange == 'BITMART') {
                return [
                    {
                        description: this.$t('tutorial.bitmartStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitmartStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitmartStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitmartStep4'),
                    },
                ];
            } else if (this.exchange == 'SWISSBORG') {
                return [
                    {
                        description: this.$t('tutorial.swissborgStep1'),
                    },
                    {
                        description: this.$t('tutorial.swissborgStep2'),
                    },
                    {
                        description: this.$t('tutorial.swissborgStep3'),
                    },
                    {
                        description: this.$t('tutorial.swissborgStep4'),
                    },
                ];
            } else if (this.exchange == 'REVOLUTX') {
                return [
                    {
                        description: this.$t('tutorial.revolutXStep1'),
                    },
                    {
                        description: this.$t('tutorial.revolutXStep2'),
                    },
                    {
                        description: this.$t('tutorial.revolutXStep3'),
                    },
                    {
                        description: this.$t('tutorial.revolutXStep4'),
                    },
                    {
                        description: this.$t('tutorial.revolutXStep5'),
                    },
                ];
            } else if (this.exchange == 'LEDGERCSV') {
                return [
                    {
                        description: this.$t('tutorial.ledgerCsvStep1'),
                    },
                    {
                        description: this.$t('tutorial.ledgerCsvStep2'),
                    },
                    {
                        description: this.$t('tutorial.ledgerCsvStep3'),
                    },
                    {
                        description: this.$t('tutorial.ledgerCsvStep4'),
                    },
                    {
                        description: this.$t('tutorial.ledgerCsvStep5'),
                    },
                ];
            }  else if (this.exchange == 'EXODUSCSV') {
                return [
                    {
                        description: this.$t('tutorial.exodusCsvStep1'),
                    },
                    {
                        description: this.$t('tutorial.exodusCsvStep2'),
                    },
                    {
                        description: this.$t('tutorial.exodusCsvStep3'),
                    },
                    {
                        description: this.$t('tutorial.exodusCsvStep4'),
                    },
                    {
                        description: this.$t('tutorial.exodusCsvStep5'),
                    },
                ];
            } else if (this.exchange == 'YOROICSV') {
                return [
                    {
                        description: this.$t('tutorial.yoroiCsvStep1'),
                    },
                    {
                        description: this.$t('tutorial.yoroiCsvStep2'),
                    },
                    {
                        description: this.$t('tutorial.yoroiCsvStep3'),
                    },
                ];
            } else if (this.exchange == 'KRIPTOMAT') {
                return [
                    {
                        description: this.$t('tutorial.kriptomatStep1'),
                    },
                    {
                        description: this.$t('tutorial.kriptomatStep2'),
                    },
                    {
                        description: this.$t('tutorial.kriptomatStep3'),
                    },
                    {
                        description: this.$t('tutorial.kriptomatStep4'),
                    },
                ];
            } else if (this.exchange == 'ONETRADING') {
                return [
                    {
                        description: this.$t('tutorial.oneTradingStep1'),
                    },
                    {
                        description: this.$t('tutorial.oneTradingStep2'),
                    },
                    {
                        description: this.$t('tutorial.oneTradingStep3'),
                    },
                    {
                        description: this.$t('tutorial.oneTradingStep4'),
                    },
                    {
                        description: this.$t('tutorial.oneTradingStep5'),
                    },
                ];
            } else if (this.exchange == 'SORARE') {
                return [
                    {
                        description: this.$t('tutorial.sorareStep1'),
                    },
                    {
                        description: this.$t('tutorial.sorareStep2'),
                    },
                    {
                        description: this.$t('tutorial.sorareStep3'),
                    },
                    {
                        description: this.$t('tutorial.sorareStep4'),
                    },
                    {
                        description: this.$t('tutorial.sorareStep5'),
                    },
                ];
            } else if (this.exchange == 'BITGET') {
                return [
                    {
                        description: this.$t('tutorial.bitgetStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep4'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep5'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep6'),
                    },
                    {
                        description: this.$t('tutorial.bitgetStep7'),
                    },
                ];
            } else if (this.exchange == 'CEXIO') {
                return [
                    {
                        description: this.$t('tutorial.cexioStep1'),
                    },
                    {
                        description: this.$t('tutorial.cexioStep2'),
                    },
                    {
                        description: this.$t('tutorial.cexioStep3'),
                    },
                    {
                        description: this.$t('tutorial.cexioStep4'),
                    },
                ];
            } else if (this.exchange == 'RAYN') {
                return [
                    {
                        description: this.$t('tutorial.raynStep1'),
                    },
                    {
                        description: this.$t('tutorial.raynStep2'),
                    },
                    {
                        description: this.$t('tutorial.raynStep3'),
                    },
                    {
                        description: this.$t('tutorial.raynStep4'),
                    },
                ];
            } else if (this.exchange == 'CONIO') {
                return [
                    {
                        description: this.$t('tutorial.conioStep1'),
                    },
                    {
                        description: this.$t('tutorial.conioStep2'),
                    },
                    {
                        description: this.$t('tutorial.conioStep3'),
                    },
                    {
                        description: this.$t('tutorial.conioStep4'),
                    },
                ];
            } else if (this.exchange == 'YOUNGPLATFORM') {
                return [
                    {
                        description: this.$t('tutorial.youngPlatformStep1'),
                    },
                    {
                        description: this.$t('tutorial.youngPlatformStep2'),
                    },
                    {
                        description: this.$t('tutorial.youngPlatformStep3'),
                    },
                    {
                        description: this.$t('tutorial.youngPlatformStep4'),
                    },
                    {
                        description: this.$t('tutorial.youngPlatformStep5'),
                    },
                    {
                        description: this.$t('tutorial.youngPlatformStep6'),
                    }
                ];
            } else if (this.exchange == 'BITSTAMP') {
                return [
                    {
                        description: this.$t('tutorial.bitstampStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitstampStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitstampStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitstampStep4'),
                    },
                    {
                        description: this.$t('tutorial.bitstampStep5'),
                    },
                    {
                        description: this.$t('tutorial.bitstampStep6'),
                    }
                ];
            } else if (this.exchange == 'BITTREX') {
                return [
                    {
                        description: this.$t('tutorial.bittrexStep1'),
                    },
                    {
                        description: this.$t('tutorial.bittrexStep2'),
                    },
                    {
                        description: this.$t('tutorial.bittrexStep3'),
                    },
                    {
                        description: this.$t('tutorial.bittrexStep4'),
                    },
                    {
                        description: this.$t('tutorial.bittrexStep5'),
                    },
                ];
            } else if (this.exchange == 'KRAKEN') {
                return [
                    {
                        description: this.$t('tutorial.krakenStep1'),
                    },
                    {
                        description: this.$t('tutorial.krakenStep2'),
                    },
                    {
                        description: this.$t('tutorial.krakenStep3'),
                    },
                    {
                        description: this.$t('tutorial.krakenStep4'),
                    },
                    {
                        description: this.$t('tutorial.krakenStep5'),
                    },
                ];
            } else if (this.exchange == 'KUCOIN') {
                return [
                    {
                        description: this.$t('tutorial.kucoinStep1'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep2'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep3'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep4'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep5'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep6'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep7'),
                    },
                    {
                        description: this.$t('tutorial.kucoinStep8'),
                    },
                ];
            } 
            /* else if (this.exchange == 'COINTRACKING') {
                return [
                    {
                        description: this.$t('tutorial.cointrackingStep1'),
                    },
                    {
                        description: this.$t('tutorial.cointrackingStep2'),
                    },
                    {
                        description: this.$t('tutorial.cointrackingStep3'),
                    },
                    {
                        description: this.$t('tutorial.cointrackingStep4'),
                    },
                ];
            } */ 
            else if (this.exchange == 'REVOLUT') {
                return [
                    {
                        description: this.$t('tutorial.revolutStep1'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep2'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep3'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep4'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep5'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep6'),
                    },
                    {
                        description: this.$t('tutorial.revolutStep7'),
                    },
                ];
            } else if (this.exchange == 'BITFINEX') {
                return [
                    {
                        description: this.$t('tutorial.bitfinexStep1'),
                    },
                    {
                        description: this.$t('tutorial.bitfinexStep2'),
                    },
                    {
                        description: this.$t('tutorial.bitfinexStep3'),
                    },
                    {
                        description: this.$t('tutorial.bitfinexStep4'),
                    },
                    {
                        description: this.$t('tutorial.bitfinexStep5'),
                    },
                    {
                        description: this.$t('tutorial.bitfinexStep6'),
                    },
                ];
            } else if (this.exchange == 'CRYPTOSMART') {
                return [
                    {
                        description: this.$t('tutorial.cryptosmartStep1'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep2'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep3'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep4'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep5'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep6'),
                    },
                    {
                        description: this.$t('tutorial.cryptosmartStep7'),
                    },
                ];
            } else {
                return [
                    {
                        description: this.$t('tutorial.csvStep1'),
                        image: require('@/assets/tutorial/CSV/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.csvStep2'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.csvStep3'),
                        image: require('@/assets/tutorial/CSV/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.csvStep4'),
                        image: require('@/assets/tutorial/CSV/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.csvStep5'),
                        image: require('@/assets/tutorial/CSV/STEP_5.png')
                    },
                ];
            }
        },
    },
    methods: {
        openDialog() {
            if (this.$vuetify.breakpoint.xs) {
                this.dialogVisible = true;
            }
        },
        goToDiscord() {
            window.open("https://discord.gg/W4p4YUDWfV", "_blank");
        },
        sendEmail() {
            var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
            var a = document.createElement('a');
            a.href = mail;
            a.click();
        }
    },
};
</script>
<style lang="scss" scoped>
.clickable-image {
    cursor: pointer;
}
ol {
  list-style-type: none;
  counter-reset: step-counter;
}

li {
  position: relative;
  margin-bottom: 10px;
  
  &:before {
    position: absolute;
    left: -30px; // Adjust the position of the counter
    top: 50%;
    transform: translateY(-50%);
    width: 20px; // Adjust the circle size as needed
    height: 20px; // Adjust the circle size as needed
    background-color:  var(--v-info-base); // Adjust the circle color as needed
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; // Adjust the text color as needed
    font-weight: bold;
    font-size: 12px; // Adjust the text size as needed
    content: counter(step-counter); // Increment the counter through CSS
    counter-increment: step-counter; // Increment the counter through CSS
  }
}
</style>
